const PropTypes = require("prop-types");
const React = require("react");
const API = require("../lib/TimeEditAPI");
import {
    MillenniumDate,
    MillenniumDateTime,
    MillenniumTime,
    SimpleDateFormat,
} from "@timeedit/millennium-time";
import TemplateKind from "../models/TemplateKind";
const ConflictListSettings = require("./ConflictListSettings");
const Language = require("../lib/Language");
const Size = require("../models/Header").Label;
const _ = require("underscore");
const GenericList = require("./GenericList");
const TC = require("../lib/TimeConstants");

const SETTINGS_TO_SAVE = [
    "beginTime",
    "endTime",
    "selectedType",
    "selectedReservationMode",
    "includeUnintentionalDoubleReservations",
    "includeIntentionalDoubleReservations",
    "includeCollisionsOutsideOfFilters",
    "objects",
    "filterObjects",
    "objectCategories",
    "doubleReservationState",
    "columnWidths",
    "selectedColumns",
    "selectedInfoColumns",
];
/*
doubleReservationObject: result[0][i],
                    firstReservation: result[1][i],
                    firstObject: result[2][i],
                    secondReservation: result[3][i],
                    secondObject: result[4][i],
                    */

// Only 90 days are allowed
const DURATION_LIMIT = 91;

const LABEL_SIZE_CUTOFFS = {
    MINIMUM: 10,
    XS: 30,
    S: 40,
    M: 60,
    L: 110,
};

const COL_IDS = {
    DR_OBJECT: 1,
    FIRST_RES: 2,
    FIRST_OBJECT: 3,
    SECOND_RES: 4,
    SECOND_OBJECT: 5,
    DATE: 6,
};

const getAvailableColumns = () => [
    {
        name: Language.get("nc_double_reservation_object"),
        primary: true,
        sortable: false,
        id: COL_IDS.DR_OBJECT,
        defaultWidth: 100,
        textAlign: "right",
    },
    {
        name: Language.get("nc_conflict_first_reservation"),
        primary: true,
        sortable: true,
        id: COL_IDS.FIRST_RES,
        defaultWidth: 100,
        textAlign: "right",
    },
    {
        name: Language.get("nc_conflict_first_object"),
        primary: true,
        sortable: false,
        id: COL_IDS.FIRST_OBJECT,
        defaultWidth: 100,
    },
    {
        name: Language.get("nc_conflict_second_reservation"),
        primary: true,
        sortable: false,
        id: COL_IDS.SECOND_RES,
    },
    {
        name: Language.get("nc_conflict_second_object"),
        primary: false,
        sortable: false,
        id: COL_IDS.SECOND_OBJECT,
    },
    {
        name: Language.get("cal_reservation_list_column_date"),
        primary: false,
        sortable: false,
        id: COL_IDS.DATE,
    },
    /*{ // To present time, probably need to figure out a useful and clear way to present the different times of the reservations
        name: Language.get("cal_reservation_list_column_time"),
        primary: false,
        sortable: false,
        id: 7
    },*/
];

class ConflictList extends React.Component {
    static defaultProps = {
        totalWidth: 0,
    };

    static contextTypes = {
        user: PropTypes.object,
        update: PropTypes.func,
        customWeekNames: PropTypes.array,
        presentModal: PropTypes.func,
        deregisterMacro: PropTypes.func,
    };

    getDefaultSearchOptions(timeLimit) {
        return {
            beginTime: timeLimit.begin,
            endTime: timeLimit.end,
            selectedType: 0,
            selectedReservationMode: 0,
            objects: [],
            filterObjects: [],
            objectCategories: {},
        };
    }

    constructor(props, context) {
        super(props, context);
        const timeLimit = this.getTimeLimits();

        this.state = {
            searchOptions: this.getDefaultSearchOptions(timeLimit),
            types: [],
            reservationModes: [],
            conflictRunning: false,
            availableObjectCategories: [],
            availableObjectCheckboxes: [],
        };
    }

    componentDidMount() {
        API.getObjectCategories(this.setState.bind(this));
        API.findAliasTypes((types) => {
            API.getTemplateGroups(TemplateKind.RESERVATION, (reservationModes) => {
                this.setState({ types, reservationModes: reservationModes.parameters[0] });
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.limits !== this.props.data.limits) {
            const timeLimits = this.getTimeLimits();
            if (
                timeLimits.begin !== this.state.searchOptions.beginTime ||
                timeLimits.end !== this.state.searchOptions.endTime
            ) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    // eslint-disable-line react/no-did-update-set-state
                    searchOptions: _.extend({}, this.state.searchOptions, {
                        beginTime: timeLimits.begin,
                        endTime: timeLimits.end,
                    }),
                });
            }
        }
    }

    onSearchSettingsChange = (settings, cb) => {
        this.setState(
            {
                searchOptions: _.extend(
                    {},
                    this.state.searchOptions,
                    _.omit(settings, (value) => value === undefined)
                ),
            },
            cb
        );
    };

    getTimeLimits = () => {
        try {
            return {
                begin: MillenniumDateTime.fromDate(this.props.data.limits.getStartDate()).getMts(),
                end: MillenniumDateTime.fromDate(this.props.data.limits.getEndDate()).getMts(),
            };
        } catch (error) {
            return {
                begin: MillenniumDateTime.fromDate(MillenniumDate.today()).getMts(),
                end: 0,
            };
        }
    };

    getDataMenuItems = (selectedOrderIds, getDataAtIndex, callback, onMassReplace) => {
        const items = [];

        if (selectedOrderIds.length === 0) {
            items.push({
                label: Language.get("nc_no_order_selected"),
                isDisabled: true,
                emphasize: true,
                action: _.noop,
            });
        }

        items.push([
            {
                label: Language.get("menu_view_info"),
                isDisabled: selectedOrderIds.length === 0,
                action: () => {
                    this.props.onInfoOpen(selectedOrderIds, true);
                },
                classNames: ["icon", "showInfo"],
            },
        ]);

        if (this.props.getDataMenuItems) {
            items.push(
                this.props.getDataMenuItems(
                    selectedOrderIds,
                    getDataAtIndex,
                    callback,
                    onMassReplace
                )
            );
        }
        return items;
    };

    find = (firstIndex, searchObjects, columns, sortOrder, sortColumn, cb) => {
        const query = {
            typeId: this.state.searchOptions.selectedType,
            objectIds: this.state.searchOptions.objects.map((obj) => obj.id),
            start: new MillenniumDateTime(this.state.searchOptions.beginTime).getMts(),
            end: new MillenniumDateTime(this.state.searchOptions.endTime).getMts(),
            includeUnintentionalDoubleReservations:
                this.state.searchOptions.includeUnintentionalDoubleReservations,
            includeIntentionalDoubleReservations:
                this.state.searchOptions.includeIntentionalDoubleReservations,
            filterObjectIds: this.state.searchOptions.filterObjects.map((obj) => obj.id),
            filterReservationModes: this.state.searchOptions.selectedReservationMode
                ? [this.state.searchOptions.selectedReservationMode]
                : [],
            includeCollisionsOutsideOfFilters:
                this.state.searchOptions.includeCollisionsOutsideOfFilters || false,
            filterCategories: this.state.searchOptions.objectCategories || [], // fieldValue
        };
        this.setState({ conflictRunning: true });
        API.getReservationConflicts(query, (result) => {
            const mappedResult = [];
            for (let i = 0; i < result[0].length; i++) {
                // doubleReservationObjects
                // firstReservations
                // firstObjects
                // secondReservations
                // secondObjects
                mappedResult.push({
                    doubleReservationObject: result[0][i],
                    firstReservation: result[1][i],
                    firstObject: result[2][i],
                    secondReservation: result[3][i],
                    secondObject: result[4][i],
                });
            }
            // eslint-disable-next-line no-undef
            /*mixpanel.track("Conflict report", {
                numberOfConflicts: mappedResult.length,
                settings: query,
            });*/
            cb(mappedResult, mappedResult.length, null, null);
            this.setState({ conflictRunning: false });
        });
    };

    onSelection = (conflict) => {
        this.props.onSelection(conflict.firstReservation, conflict.secondReservation);
    };

    getCellLabels = (order, columns, columnWidths) => {
        const labels = columns.map((column, i) => {
            if (!order) {
                return "";
            }
            if (column.name === Language.get("nc_double_reservation_object")) {
                return order.doubleReservationObject.fields[0].values.join(", ");
            }
            if (column.name === Language.get("nc_conflict_first_reservation")) {
                return String(order.firstReservation.id);
            }
            if (column.name === Language.get("nc_conflict_first_object")) {
                return order.firstObject.fields[0].values.join(", ");
            }
            if (column.name === Language.get("nc_conflict_second_reservation")) {
                return String(order.secondReservation.id);
            }
            if (column.name === Language.get("nc_conflict_second_object")) {
                return order.secondObject.fields[0].values.join(", ");
            }
            const width = columnWidths[i];
            let format;
            const reservation = order.firstReservation;
            const startTime = reservation.begin ? new MillenniumDateTime(reservation.begin) : null;
            const endTime = reservation.end ? new MillenniumDateTime(reservation.end) : null;
            // eslint-disable-next-line no-magic-numbers
            const between = startTime && endTime ? startTime.daysBetween(endTime) : 2;
            const isSameDay = between === 0 || (between === 1 && endTime.isMidnight());
            if (
                column.name === Language.get("cal_reservation_list_column_date") &&
                reservation.begin
            ) {
                if (!isSameDay) {
                    // Different start and end date
                    format = Language.getDateFormat("date_f_m_d");
                    const start = SimpleDateFormat.format(startTime, format);
                    const end = SimpleDateFormat.format(
                        endTime,
                        Language.getDateFormat("date_f_m_d_end")
                    );
                    if (start !== end) {
                        return `${start}-${end}`;
                    }
                    return start;
                }
                format = Language.getDateFormat("date_f_yy_mm_dd");
                return SimpleDateFormat.format(startTime, format);
            }
            if (
                column.name === Language.get("cal_reservation_list_column_time") &&
                reservation.begin
            ) {
                return `${this.getTime(startTime, width, false)}-${this.getTime(
                    endTime,
                    width,
                    true
                )}`;
            }
            if (column.name === Language.get("cal_reservation_list_column_length")) {
                const length = reservation.length
                    ? reservation.length
                    : reservation.end - reservation.begin;
                if (length === 0 || isNaN(length)) {
                    return "0:00";
                }
                const hours = Math.floor(length / TC.SECONDS_PER_HOUR);
                const minutes = Math.floor((length % TC.SECONDS_PER_HOUR) / TC.SECONDS_PER_MINUTE);
                // eslint-disable-next-line no-magic-numbers
                return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
            }
            return "";
        });

        return { labels };
    };

    getTime = (time, factor, size) => {
        if (factor > 1) {
            // eslint-disable-next-line no-param-reassign
            time = Math.floor(time / factor);
            return Math.floor(time / TC.SECONDS_PER_MINUTE);
        }
        // eslint-disable-next-line no-param-reassign
        time = new MillenniumTime(time);
        switch (this.getLabelSize(size)) {
            case Size.M:
                return SimpleDateFormat.format(time, Language.getDateFormat("date_f_hh"));
            default:
                return SimpleDateFormat.format(time, Language.getDateFormat("date_f_hh_mm"));
        }
    };

    getLongDate = (date, size) => {
        switch (this.getLabelSize(size)) {
            case Size.XS:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_d"));
            case Size.S:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_d"));
            case Size.M:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_m_d"));
            case Size.L:
                return SimpleDateFormat.format(date, Language.getDateFormat("date_f_ee_m_d"));
            default:
                return SimpleDateFormat.format(
                    date,
                    Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                );
        }
    };

    getLabelSize = (size) => {
        if (size <= LABEL_SIZE_CUTOFFS.MINIMUM) {
            return null;
        }
        if (size < LABEL_SIZE_CUTOFFS.XS) {
            return Size.XS;
        }
        if (size < LABEL_SIZE_CUTOFFS.S) {
            return Size.S;
        }
        if (size < LABEL_SIZE_CUTOFFS.M) {
            return Size.M;
        }
        if (size < LABEL_SIZE_CUTOFFS.L) {
            return Size.L;
        }
        return Size.XL;
    };

    isSearchButtonActive() {
        const start = new MillenniumDateTime(this.state.searchOptions.beginTime);
        const end = new MillenniumDateTime(this.state.searchOptions.endTime);
        return (
            !this.state.conflictRunning &&
            this.state.searchOptions.selectedType > 0 &&
            MillenniumDateTime.lengthInDays(start, end) < DURATION_LIMIT
        );
    }

    render() {
        const classes = {
            conflictList: true, // Was orderList
        };

        return (
            <GenericList
                {...this.props}
                settingsToSave={SETTINGS_TO_SAVE}
                onSearchSettingsChange={this.onSearchSettingsChange}
                showSelectionSummary={false}
                allowMultiSelection={true}
                searchOptions={this.state.searchOptions}
                onSelection={this.onSelection}
                getCellLabels={this.getCellLabels}
                columns={getAvailableColumns()}
                defaultSelectedColumns={[
                    COL_IDS.DR_OBJECT,
                    COL_IDS.FIRST_RES,
                    COL_IDS.FIRST_OBJECT,
                    COL_IDS.SECOND_RES,
                    COL_IDS.SECOND_OBJECT,
                ]}
                getDataMenuItems={this.getDataMenuItems}
                title={Language.get("nc_conflict_list")}
                classes={classes}
                find={this.find}
                getSettingsComponent={this.getSettingsComponent}
                supportsSearch={false}
                manualSearchButton={true}
                isSearchButtonActive={this.isSearchButtonActive()}
                showLoadIndicator={this.state.conflictRunning}
            />
        );
    }

    loadSettings = (callback, getSavedSettings) => {
        const defaultSettings = this.getDefaultSearchOptions(this.getTimeLimits());
        getSavedSettings((result) => {
            result.forEach((r) => {
                // eslint-disable-next-line no-param-reassign
                r.settings = _.extend({}, defaultSettings, r.settings);
            });
            callback(result);
        });
    };

    limitTo = (categories, allowedCategories) => {
        const result = _.omit(
            categories,
            (value, category) =>
                !_.some(
                    allowedCategories,
                    (allowedCategory) =>
                        String(allowedCategory.id) === category &&
                        _.every(value, (val) => _.contains(allowedCategory.categories, val))
                )
        );
        return result;
    };

    getSettingsComponent = (
        saveDefaultSettings,
        saveSettings,
        getSavedSettings,
        removeSettings
    ) => {
        const opts = this.state.searchOptions;

        return (
            <ConflictListSettings
                context={this.context}
                onChange={this.onSearchSettingsChange}
                saveDefaultSettings={saveDefaultSettings}
                saveSettings={saveSettings}
                getSavedSettings={(cb) => this.loadSettings(cb, getSavedSettings)}
                removeSettings={removeSettings}
                beginTime={this.state.searchOptions.beginTime}
                endTime={this.state.searchOptions.endTime}
                selectedType={this.state.searchOptions.selectedType}
                selectedReservationMode={this.state.searchOptions.selectedReservationMode}
                objects={this.state.searchOptions.objects}
                filterObjects={this.state.searchOptions.filterObjects}
                objectCategories={this.limitTo(
                    opts.objectCategories,
                    this.state.availableObjectCategories.concat(
                        this.state.availableObjectCheckboxes
                    )
                )}
                availableObjectCategories={this.state.availableObjectCategories}
                availableObjectCheckboxes={this.state.availableObjectCheckboxes}
                runReport={() => this.find(0)}
                conflictRunning={this.state.conflictRunning}
                types={this.state.types}
                reservationModes={this.state.reservationModes}
                includeUnintentionalDoubleReservations={
                    this.state.searchOptions.includeUnintentionalDoubleReservations
                }
                includeIntentionalDoubleReservations={
                    this.state.searchOptions.includeIntentionalDoubleReservations
                }
                includeCollisionsOutsideOfFilters={
                    this.state.searchOptions.includeCollisionsOutsideOfFilters
                }
            />
        );
    };
}

module.exports = ConflictList;
